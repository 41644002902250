import remoteImg from "./assets/products/Remote(Kumanda).png";
import monitorImg from "./assets/products/monitor.png";
import sensorImg from "./assets/products/Harici-sensor.png";
import internalImg from "./assets/products/internal.png";
import reparter from "./assets/products/reparter.png";
import antiTheftImg from "./assets/products/AntiTheft-sensor.png";
import beltSensorImg from "./assets/products/Belt-sensor.png";
import remoteAccessImg from "./assets/products/Uzaktan-Erisim.png";
import spImg from "./assets/products/sp3.png";
//import more images here

const productsData = [
  {
    title: "Remote (Kumanda)",
    description:
      "Uzaktan bilgilendirme için sensörlerin poziyonlarına göre programlama yapar.",
    image: remoteImg,
    specifications: [
      "Kolay kodlama",
      "Lastik içinde jant genişliği üzerinde konumlama",
      "Tüm jant tipleri ile uyumlu",
      "Su geçirmez",
      "Sensöre bağlanan özel subap",
      "Ani hava kaybı bilgisi",
      "Ayrı ayrı kodlanabilme özelliği",
    ],
    name: "remote",
  },
  {
    title: "Dahili Sensör",
    description:
      "Takıldığı lastiğin basınç ve sıcaklıklarını ölçerek kablosuz bir şekilde monitöre iletir.",

    specifications: [
      "Kolay kodlama",
      "Lastik içinde jant genişliği üzerinde konumlama",
      "Tüm jant tipleri ile uyumlu",
      "Su geçirmez",
      "Sensöre bağlanan özel subap",
      "Ani hava kaybı bilgisi",
      "Ayrı ayrı kodlanabilme özelliği",
    ],
    image: internalImg,
    name: "internal",
  },
  {
    title: "Harici Sensör (İş makinası)",
    description:
      "Takıldığı lastiğin basınç ve sıcaklıklarını ölçerek kablosuz bir şekilde monitöre iletir.",
    specifications: [
      "Güvenilir ve kolay kodlanabilir kapak sensörler",
      "Su geçirmez özellik",
      "Değişebilen pil özelliği.",
      "Ani hava kaybı alarmı",
      "Ayrı ayrı kodlanabilme özelliği.",
      "Çalınmaya karşı korumalı dizayn",
      "Lastik değişince tekrar kodlama gerektirmez",
    ],
    image: sensorImg,
    name: "sensor",
  },
  {
    title: "Monitor",
    description:
      "Aracınızın lastik pozisyonuna göre basınç ve sıcaklık bilgilerinizi anlık olarak görüntülemenizi sağlar.",
    specifications: [
      "Güvenilir ve kolay kurulum",
      "Geniş LCD ekran",
      "Şarj edilebilir lityum pil",
      "Otomatik arka ışıklandırma",
      "Ayarlanabilir düşük/yüksek hava basınç uyarı baremi",
      "Yüksek sıcaklık uyarı baremi.",
      "Duyusal ve görsel alarm",
      "Seçilebilir basınç birimleri",
      "22 lastik görüntüleme",
      "Monitor ve sensörler arası uzak mesafe iletişim gücü",
      "Araç aksına göre ayarlanabilir basınç baremleri",
    ],
    image: monitorImg,
    name: "monitor",
  },
  {
    title: "Sinyal Güçlendirici",
    description:
      "Uzaktan bilgilendirme sistemi için kullanılır. Sinyal gönderim stabilitesi sağlar. ",
    specifications: [
      "Sinyal gönderim stabilitesi sağlar.",
      "Sensör bilgilerini ve düşük –yüksek hava basıncı ve yüksek sıcaklık baremlerini kaydeder.",
      "Treyler değişim durumunda tek tuş ile ,bağlanan çekici monitörüne bilgi aktarımı",
      "Sensörler ve monitör arasında bilgi transferi sağlar.",
      "Aktarılan sensör kimlik bilgilerini ve düşük- yüksek hava basınç ve yüksek sıcaklık baremlerini hafızada tutar.",
    ],
    image: reparter,
    name: "reparter",
  },

  //examples AntiTheft, Harici Sensor, Belt Sensor
  {
    title: "Uzaktan Erişim Cihazı",
    description:
      "Sisteme ait bilgileri operatör ekranından bağımsız bir şekilde online platforma iletir.",
    specifications: [
      "M2M kart ile çalışır.",
      "Araç konum ve günlük parkur bilgilerine ulaşım imkanı sağlar.",
      "Geçmişe dönük alarm durumlarını excel tablosu olarak raporlar.",
      "Gücü, bağlandığı aracın sigorta panelinden alarak çalışır.",
      "Eş zamanlı bilgi akışı ile anlık alarm durumlarını iletir.",
    ],
    image: remoteAccessImg,
    name: "remoteAccess",
  },
  {
    title: "Harici Sensör (AFTES)",
    description: "Çalınmaya karşı anti-theft özelliği vardır.",
    specifications: [
      "Güç kaynağı olan CR 2032 pil ile çalışır.",
      "Araç hareket halinde iken güç kullanırlar, durduğunda enerji tasarrufu moduna geçerler. RFID ile iletişim sağlarlar",
      "Ortalama pil ömrü ; 200.000 - 250.000 km civarındadır.",
      "Pil ömrü bittiğinde yeni pil ile değiştirilerek kullanım devam edilebilir. Fiziksel şartlardan etkilenmezler.",
    ],
    image: antiTheftImg,
    name: "antiTheft",
  },
  {
    title: "Kelepçe Sensör",
    description:
      "Suya dayanıklı yapısı ve çalınmaya karşı korumalı tasarımı sayesinde dayanıklılığı ve güvenliği ön planda tutar. ",
    specifications: [
      "Kolay kurulumlu kapak sensörleri.",
      "Suya dayanıklı yapı",
      "Basınç ve sıcaklık verisi aktarımı.",
      "Hızlı sızıntı uyarı sistemi.",
      "Bireysel olarak kodlanmış sensörler.",
    ],
    image: beltSensorImg,
    name: "beltSensor",
  },
  {
    title: "Harici Sensör",
    description:
      "Takıldığı lastiğin basınç ve sıcaklıklarını ölçerek kablosuz bir şekilde monitöre iletir.",
    specifications: [
      "Pil ömrü tükendiğinde sensörün bataryası kolayca değiştirilebilir, bu da uzun ömürlü kullanım sağlar.",
      "Sensörü çıkarmadan lastiklerinize hava eklemenizi sağlar.",
      "Güç kaynağı olan CR 2032 pil ile çalışır.",
      "Araç hareket halinde iken güç kullanırlar, durduğunda enerji tasarrufu moduna geçerler. RFID ile iletişim sağlarlar",
      "Ortalama pil ömrü ; 200.000 - 250.000 km civarındadır.",
      "Pil ömrü bittiğinde yeni pil ile değiştirilerek kullanım devam edilebilir. Fiziksel şartlardan etkilenmezler.",
    ],
    image: spImg,
    name: "sp",
  },
  // dummy datas
];

export default productsData;
